export function objectToFormData(obj) {
  const formData = new FormData();
  Object.keys(obj).forEach((dataKey) => {
    if (Array.isArray(obj[dataKey])) {
      obj[dataKey].forEach((file) => formData.append(`${dataKey}[]`, file));
    } else {
      formData.append(dataKey, obj[dataKey]);
    }
  });
  return formData;
}