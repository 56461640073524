<template>
  <div class="card-file">
    <div class="card-file__content">
      <component
        :is="link ? 'a' : 'span'"
        :href="link"
        target="_blank"
        class="card-file__content-name"
      >
        <u><b>{{ name }}</b></u>
      </component>
      <span>{{ size }}</span>
    </div>
    <div class="card-file__actions">
      <button v-if="showDelete" type="button" class="btn card-file__actions-close" @click="deleteHandle">
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px">
          <title/>
          <g id="cross">
            <line fill="none" stroke="#545454" stroke-linecap="round" stroke-linejoin="round" stroke-width="2px" x1="7" x2="25" y1="7" y2="25"/>
            <line fill="none" stroke="#545454" stroke-linecap="round" stroke-linejoin="round" stroke-width="2px" x1="7" x2="25" y1="25" y2="7"/>
          </g>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileCard',
  props: {
    name: {
      type: [String, Number],
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    showDelete: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    deleteHandle() {
      this.$emit('delete')
    }
  }
}

</script>

<style scoped lang="scss">
.card-file {
  display: flex;
  justify-content: space-between;
  max-width: 180px;
  width: 100%;
  padding: .571rem;
  background-color: #f1f1f1f1;

  &__icon, &__content {
    margin-right: .57rem;
  }

  &__content {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &-name {
      width: 100%;
      margin: 6px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__actions {
    height: 100%;
    &-close {
      background-color: inherit;
      padding: 0;
      border: none;
      box-shadow: none;
    }
  }
}
</style>