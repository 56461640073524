<template>
  <div class="app-input__container">
    <div
      :class="{
        'app-input': true,
        'app-input__active': isFocused || value.toString(),
        'app-input__focus': isFocused,
      }"
    >
      <label :class="`app-input__label ${labelClass}`" :for="name">{{ label }}</label>
      <input
        type="text"
        :class="inputClass"
        :id="name"
        :value="value"
        :name="name"
        :required="required"
        @input="inputPointsHandle"
        @focus="focusInputHandle"
        @blur="blurInputHandle"
      >
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: [String, Object],
      default: '',
    },
    labelClass: {
      type: [String, Object],
      default: '',
    },
  },
  data() {
    return {
      isFocused: false,
    }
  },
  methods: {
    focusInputHandle(e) {
      this.isFocused = true;
      this.$emit('focus', e);
    },
    blurInputHandle(e) {
      this.isFocused = false;
      this.$emit('blur', e);
    },
    inputPointsHandle(e) {
      this.$emit('input', e);
    }
  }
}
</script>

<style lang="scss">
.app-input__container {
  padding-top: 16px;
  padding-bottom: 4px;
  margin-top: 4px;
}
.app-input {
  position: relative;
  & input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem 0.375rem 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-top: unset !important;
    border-left: unset !important;
    border-right: unset !important;
    border-bottom: 1px solid rgba(0,0,0,.42) !important;
    outline: none;
    transition: .15s ease-in-out;
  }
  &:focus {
    border-color: #1976d2 !important;
    box-shadow: 0 1px 0 0 #1976d2;
  }
  &__active &__label {
    transform: translateY(-24px) scale(.95);
  }
  &__focus &__label {
    color: #1976d2;
  }
  &__label {
    position: absolute;
    font-size: 1rem;
    transform-origin: top left;
    left: 0;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    top: 8px;
    white-space: nowrap;
    pointer-events: none;
    transition: .3s cubic-bezier(.25,.8,.5,1);
  }
}
</style>