export const defaultRequestForm = {
  points: '',
  writeDownFor: '',
  actFiles: [],
  accountFiles: [],
  otherFiles: [],
  filesForDelete: [],
  accountFilesExists: [],
  actFilesExists: [],
  otherFilesExists: [],
}