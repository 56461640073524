var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.open)?_c('div',{ref:"requestOverlay",staticClass:"request-form__overlay",on:{"click":_vm.outsideClickHandle}},[_c('ValidationObserver',{ref:"validationObserver",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"request-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitHandle)}}},[_c('div',{staticClass:"request-form__title"},[_c('h3',{staticClass:"request-form__title-text"},[_vm._v(_vm._s(_vm.title))]),_c('button',{staticClass:"btn",attrs:{"type":"button"},on:{"click":_vm.cancelHandle}},[_c('svg',{attrs:{"viewBox":"0 0 32 32","xmlns":"http://www.w3.org/2000/svg","width":"24px","height":"24px"}},[_c('title'),_c('g',{attrs:{"id":"cross"}},[_c('line',{attrs:{"fill":"none","stroke":"#545454","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2px","x1":"7","x2":"25","y1":"7","y2":"25"}}),_c('line',{attrs:{"fill":"none","stroke":"#545454","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2px","x1":"7","x2":"25","y1":"25","y2":"7"}})])])])]),_c('div',{staticClass:"request-form__content"},[_c('ValidationProvider',{staticClass:"d-block",attrs:{"name":_vm.form.points.toString(),"rules":"required|integer|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppInput',{attrs:{"value":_vm.form.points,"name":"points","label":"Сумма на списание *"},on:{"input":_vm.inputPointsHandle}}),_c('span',{staticClass:"request-form__error d-block text-danger my-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group d-block",attrs:{"name":_vm.form.writeDownFor,"rules":("required|excluded:" + _vm.defaultWriteDownFor)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppInput',{attrs:{"value":_vm.form.writeDownFor,"name":"writeDownFor","label":"Списание баллов за (введите основание для списания) *"},on:{"input":_vm.inputWriteDownForHandle,"blur":_vm.blurWriteDownForHandle,"focus":_vm.focusWriteDownForHandle}}),_c('span',{staticClass:"request-form__error d-block text-danger mt-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group d-block",attrs:{"rules":_vm.isUpdate && _vm.form.actFilesExists.length
            ? 'ext:pdf,png,jpeg,jpg,msg|maxSize:200000'
            : 'required|ext:pdf,png,jpeg,jpg,msg|maxSize:200000'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var validate = ref.validate;
return [_c('div',[_c('label',{staticClass:"request-form__label",attrs:{"for":"actFiles"}},[_vm._v("Акт/накладная/протокол *")]),_c('input',{ref:"actFiles",staticClass:" request-form__input",attrs:{"type":"file","multiple":"","id":"actFiles","name":"actFiles"},on:{"input":function($event){return _vm.inputFilesHandle($event, 'actFiles')},"change":validate}})]),_c('span',{class:{ 'd-block my-2 request-form__error': true, 'text-danger': errors.length }},[_vm._v(" "+_vm._s(errors[0] || 'Форматы pdf, jpeg, jpg, png, msg. Не более 200 Мб')+" ")]),(_vm.form.actFilesExists.length || _vm.form.actFiles.length)?_c('div',{staticClass:"request-form__content-files"},[_vm._l((_vm.form.actFilesExists),function(file,index){return _c('FileCard',{key:index,attrs:{"name":file.name,"size":_vm.fileSizeFormatter(+file.size)},on:{"delete":function($event){return _vm.deleteFile(file, index, 'actFiles')}}})}),_vm._l((_vm.form.actFiles),function(file,index){return _c('FileCard',{key:index,attrs:{"name":file.name,"size":_vm.fileSizeFormatter(+file.size)},on:{"delete":function($event){return _vm.deleteFile(file, index, 'actFiles')}}})})],2):_vm._e()]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group d-block",attrs:{"rules":_vm.isUpdate && _vm.form.accountFilesExists.length
          ? 'ext:pdf,png,jpeg,jpg,msg|maxSize:200000'
          : 'required|ext:pdf,png,jpeg,jpg,msg|maxSize:200000'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var validate = ref.validate;
return [_c('div',[_c('label',{staticClass:"request-form__label",attrs:{"for":"accountFiles"}},[_vm._v("Счёт *")]),_c('input',{ref:"accountFiles",staticClass:" request-form__input",attrs:{"type":"file","multiple":"","id":"accountFiles","name":"accountFiles"},on:{"input":function($event){return _vm.inputFilesHandle($event, 'accountFiles')},"change":validate}})]),_c('span',{class:{ 'd-block my-2 request-form__error': true, 'text-danger': errors.length }},[_vm._v(" "+_vm._s(errors[0] || 'Форматы pdf, jpeg, jpg, png, msg. Не более 200 Мб')+" ")]),(_vm.form.accountFilesExists.length || _vm.form.accountFiles.length)?_c('div',{staticClass:"request-form__content-files"},[_vm._l((_vm.form.accountFilesExists),function(file,index){return _c('FileCard',{key:index,attrs:{"name":file.name,"size":_vm.fileSizeFormatter(+file.size)},on:{"delete":function($event){return _vm.deleteFile(file, index, 'accountFiles')}}})}),_vm._l((_vm.form.accountFiles),function(file,index){return _c('FileCard',{key:index,attrs:{"name":file.name,"size":_vm.fileSizeFormatter(+file.size)},on:{"delete":function($event){return _vm.deleteFile(file, index, 'accountFiles')}}})})],2):_vm._e()]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group d-block",attrs:{"rules":"ext:pdf,png,jpeg,jpg,msg|maxSize:200000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var validate = ref.validate;
return [_c('div',[_c('label',{staticClass:"request-form__label",attrs:{"for":"otherFiles"}},[_vm._v("Прочие подтверждающие документы")]),_c('input',{ref:"otherFiles",staticClass:" request-form__input",attrs:{"type":"file","multiple":"","id":"otherFiles","name":"otherFiles"},on:{"input":function($event){return _vm.inputFilesHandle($event, 'otherFiles')},"change":validate}})]),_c('span',{class:{ 'd-block my-2 request-form__error': true, 'text-danger': errors.length }},[_vm._v(" "+_vm._s(errors[0] || 'Форматы pdf, jpeg, jpg, png, msg. Не более 200 Мб')+" ")]),(_vm.form.otherFilesExists.length || _vm.form.otherFiles.length)?_c('div',{staticClass:"request-form__content-files"},[_vm._l((_vm.form.otherFilesExists),function(file,index){return _c('FileCard',{key:index,attrs:{"name":file.name,"size":_vm.fileSizeFormatter(+file.size)},on:{"delete":function($event){return _vm.deleteFile(file, index, 'otherFiles')}}})}),_vm._l((_vm.form.otherFiles),function(file,index){return _c('FileCard',{key:index,attrs:{"name":file.name,"size":_vm.fileSizeFormatter(+file.size)},on:{"delete":function($event){return _vm.deleteFile(file, index, 'otherFiles')}}})})],2):_vm._e()]}}],null,true)}),_c('p',{staticClass:"request-form__text"},[_vm._v(" Со списком обязательных документов для подтверждения списания просим ознакомиться по "),_c('u',[_c('router-link',{attrs:{"to":"/catalog/materialy-dlya-oformleniya-sto-i-rtt","target":"_blank"}},[_vm._v("ссылке")])],1)])],1),_c('div',{staticClass:"request-form__actions"},[_c('button',{staticClass:"btn btn-red",attrs:{"type":"button"},on:{"click":_vm.cancelHandle}},[_vm._v("Отменить")]),_c('button',{staticClass:"btn btn-red",attrs:{"type":"submit","disabled":invalid}},[_vm._v("Отправить зявку")])])])]}}],null,false,2429632366)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }