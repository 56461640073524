<template>
  <div class="requests-wrapper">
    <RequestForm
      :open="toggleCreateForm"
      @cancel="toggleCreateFormHandle"
      @submit="submitRequestsHandle"
    />
    <div class="requests-wrapper__actions">
      <button class="btn btn-red" @click="toggleCreateFormHandle">Создать заявку</button>
    </div>
    <div class="requests-content">
      <div
        class="flex-table"
        role="table"
      >
        <div class="flex-table-row flex-table-header flex-row">
          <div class="flex-table-cell">Статус</div>
          <div class="flex-table-cell">Дата создания</div>
          <div class="flex-table-cell">Номер заявки</div>
          <div class="flex-table-cell">Сумма списания</div>
          <div class="flex-table-cell">Основание списания</div>
        </div>
        <div v-if="requests.length" id="accordion" class="flex-table-body accordion">
          <RequestItem
            class="flex-table-row flex-column accordion-item"
            v-for="item in requests"
            :key="item.id"
            :request="item"
            @update="updateRequestHandle"
            @cancel="cancelRequestHandle"
          />
        </div>
        <div v-else class="d-flex justify-content-md-center">
          <div class="ss-header">
            <span class="title-desc"><strong>Тут пока ничего нет.</strong></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RequestForm from "@/components/profile/requests-write-down-points/RequestForm.vue";
import {mapGetters} from "vuex";
import {showSuccess} from "@/lib/swal2";
import RequestItem from "@/components/profile/requests-write-down-points/RequestItem.vue";
import {objectToFormData} from "@/utilities/requests-write-down-points";
import api from "@/api";
import {removeLoading, setLoading} from "@/lib/loading";
export default {
  name: 'RequestsWriteDownPoints',
  components: {
    RequestItem,
    RequestForm,
  },
  data() {
    return {
      toggleCreateForm: false,
      requests: []
    }
  },
  computed: {
    ...mapGetters('account', ['pointsNumber']),
  },
  methods: {
    toggleCreateFormHandle() {
      this.toggleCreateForm = !this.toggleCreateForm;
    },
    getRequests() {
      api.requestsWriteDownPoints.getRequestsWriteDownPoints().then((response) => {
        this.requests = response.data;
      });
    },
    async submitRequestsHandle(form) {
      if (form.points > this.pointsNumber) {
        showSuccess("На аккаунте недостаточно бонусов!");
        return;
      }
      setLoading()
      try {
        await api.requestsWriteDownPoints.postRequestsWriteDownPoints(objectToFormData(form));
        this.getRequests();
      } catch (e) {
        console.error(e);
      }
      this.toggleCreateForm = false;
      removeLoading()
    },
    async cancelRequestHandle(requestId) {
      try {
        await api.requestsWriteDownPoints.cancelRequestsWriteDownPoints({
          id: requestId,
        });
      } catch(e) {
        console.log(e);
      }
      this.getRequests();
    },
    async updateRequestHandle(form) {
      await api.requestsWriteDownPoints.putRequestsWriteDownPoints(form);
      this.getRequests();
    }
  },
  mounted() {
    this.getRequests();
  },
  metaInfo() {
    return {
      title: `Профиль - Заявки на списание - ЛУКМАРКЕТ`,
    };
  },
}
</script>

<style lang="scss" scoped>
.ss-header {
  padding: 20px 0;
  border-bottom: 2px solid #f5f5f5;
}
.requests-wrapper {
  padding: 20px 0;
  &__actions {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
.flex-table {
  display: flex;
  flex-direction: column;

  &-header {
    font-weight: bold;
    border-bottom: 1px solid #dee2e6;

    @media (max-width: 992px) {
      display: none !important;
    }
  }

  &-row {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 992px) {
      flex-direction: row;
    }
  }

  &-cell {
    padding: 0.5rem;
    word-wrap: break-word;

    @media (min-width: 992px) {
      padding: 1rem;
      width: calc(100% / 4);
    }
  }
}

</style>